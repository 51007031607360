
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';


import Gallery, { UploadPhotoEvent, DeletePhotoEvent } from '@/components/Gallery.vue';
import {
  getModel,
  createModelPhoto,
  deleteModelPhoto
} from '@/services/api';

import { PartialModel } from '@/interfaces/Model';

export default defineComponent({
  components: {
    Gallery
  },
  setup() {
    const modelId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const model = ref<PartialModel>({});
    const { t } = useI18n();


    const fetchModels = async() => {
      const res = await getModel({ modelId });
      model.value = res.data;
    };

    const handleUploadPhoto = async({ index, photo }: UploadPhotoEvent) => {
      console.log({ index, photo });
      await createModelPhoto({
        modelId,
        data: {
          image: photo.path
        }
      });

      fetchModels();
    };

    const handleDeletePhoto = async({ index }: DeletePhotoEvent) => {
      console.log({ index });

      await deleteModelPhoto({
        modelId,
        photoId: `${model.value.photos[index].id}`
      });

      fetchModels();
    };

    onMounted(() => {
      fetchModels();
    });

    return {
      page,
      model,
      handleUploadPhoto,
      t,
      handleDeletePhoto
    };
  }
});
